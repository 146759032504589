import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Card, CardBody } from "@progress/kendo-react-layout";

import { useNotificationContext } from "@src/common/Context";
import axios from "@src/common/http";
import { Link } from "@components/common";
import LoadingPanel from "@components/common/LoadingPanel";

const baseClass = "acl-page-auth";

const VerifyEmail: React.FC<{}> = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { token } = useParams<{ token: string }>();
    const navigate = useNavigate();
    const { sendNotification } = useNotificationContext();

    const verifyEmail = async (): Promise<void> => {
        const response = await axios.get(`/portal/verifyEmail?token=${token}`);
        const { status, data } = response;

        if (status === 200) {
            sendNotification({
                timeout: 10000,
                type: "success",
                message: data?.message || "Your email is now verified.",
            });
            setIsLoading(false);
        } else {
            navigate("/login");
        }
    };

    useEffect(() => {
        verifyEmail();
    }, []);

    return (
        <main className={`${baseClass} ${baseClass}--login`}>
            <div className={`${baseClass}__success-wrapper`}>
                <h1>Client Portal</h1>
                <Card className={`${baseClass}__content`}>
                    <CardBody>
                        {isLoading ? (
                            <LoadingPanel />
                        ) : (
                            <>
                                <h3 className={`${baseClass}__title`}>Thanks for your verification.</h3>
                                <p>Please note that we need to verify your authorization before we activate your account.</p>
                                <p>This may take up to 1-2 business days.</p>
                                <div className={`k-form-buttons ${baseClass}__actions`}>
                                    <Link to="/faq" type="button" primary>
                                        Back to Login
                                    </Link>
                                </div>
                            </>
                        )}
                    </CardBody>
                </Card>
            </div>
        </main>
    );
};

export default VerifyEmail;
