import { Card } from "@progress/kendo-react-layout";
import Link from "../Link";
import "./Navigation.scss";
import React from "react";
import classNames from "classnames";

const baseClass = "acl-navigation";

type NavigationRoute = {
    label: string;
    to?: string;
    isActive: boolean;
};
interface INavigationProps {
    routes: NavigationRoute[];
    onClick?(label: string): void;
}

const Navigation: React.FC<INavigationProps> = ({ routes, onClick }) => (
    <Card className={`${baseClass}__card`}>
        <nav>
            <ul>
                {routes.map(route =>
                    route.to ? (
                        <li key={route.label}>
                            <Link to={route.to} active={route.isActive}>
                                {route.label}
                            </Link>
                        </li>
                    ) : (
                        <li key={route.label}>
                            <span
                                className={classNames(`${baseClass}__tab`, route.isActive ? `${baseClass}__tab-active` : null)}
                                onClick={(): void => onClick(route.label)}
                            >
                                {route.label}
                            </span>
                        </li>
                    ),
                )}
            </ul>
        </nav>
    </Card>
);

export default Navigation;
