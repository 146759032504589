import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import Mark from "mark.js";
import classNames from "classnames";

import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";

import { useStateEx as useState } from "@src/common/hooks";
import Page, { Title } from "@components/containers/Page";
import { BackToTop, LoadingPanel, FilterInput, Icon } from "@components/common";

import "./Glossary.scss";
import { GET_GLOSSARY } from "@src/common/graphql";
import { useLocation, useSearchParams } from "react-router-dom";
import { scrollHashElementIntoView } from "@src/common/util";
import { useNotificationContext } from "@src/common/Context";

const baseClass = "acl-page-glossary";

type TGlossaryItem = {
    abbreviation: string;
    title: string;
    definition: string;
};

type TGlossary = {
    [letter: string]: Array<TGlossaryItem>;
};

export const Letters: React.FC<{ letters: string[] }> = ({ letters = [] }) => {
    const classes: string = classNames(`${baseClass}__letters`, letters.length < 15 && `${baseClass}__letters--fewer`);

    return letters.length ? (
        <nav className={`${classes}`}>
            {letters.map(letter => (
                <a href={`#abbr-${letter}`} key={letter} className={`${baseClass}__letter`}>
                    {letter}
                </a>
            ))}
        </nav>
    ) : null;
};

export const filterData = (glossary: TGlossary, filter: string): TGlossary => {
    const result = {};
    const keyword = filter.toLowerCase();
    const entries = Object.entries(glossary);

    entries.forEach(([letter, items]) => {
        const filtered = items.filter(
            ({ title, definition, abbreviation }) =>
                (title && title.toLowerCase().includes(keyword)) ||
                (definition && definition.toLowerCase().includes(keyword)) ||
                (abbreviation && abbreviation.toLowerCase().includes(keyword)),
        );

        if (filtered.length) {
            result[letter] = filtered;
        }
    });

    return result;
};

const Glossary: React.FC<{}> = () => {
    const [filter, setFilter] = useState("");
    const [filteredGlossary, setFilteredGlossary] = useState({});
    const [searchParams, _setSearchParams] = useSearchParams();
    const location = useLocation();
    const { sendNotification } = useNotificationContext();

    const { loading, error, data } = useQuery(GET_GLOSSARY, {
        fetchPolicy: "cache-first",
        onCompleted: data => {
            setFilteredGlossary(data?.glossary ?? {});
        },
    });

    const handleSeach = (keyword: string): void => setFilter(keyword);

    const letters = Object.keys(filteredGlossary).sort();

    const copyLinkToClipboard = (id: number): void => {
        navigator.clipboard.writeText(`${window.location.origin}${location.pathname}?id=${id}`);
        sendNotification({
            timeout: 6000,
            type: "success",
            message: "Link is coppied to clipboard.",
        });
    };

    useEffect(() => {
        setFilteredGlossary(filterData(data?.glossary ?? {}, filter));

        const markInstance = new Mark(document.getElementById("search-node"));

        markInstance.unmark({
            done: () => markInstance.mark(filter, { className: `${baseClass}__highlight` }),
        });
    }, [filter]);

    useEffect(() => {
        scrollHashElementIntoView(searchParams.get("id"));
    }, [filteredGlossary]);

    return (
        <Page className={baseClass}>
            <Title className={`${baseClass}__title`}>Glossary</Title>
            <FilterInput placeholder={"Type to filter glossary..."} onChange={handleSeach} />
            <div className={`${baseClass}__list`}>
                <header className={`${baseClass}__list__header`}>
                    <Letters letters={letters} />
                </header>
                <div id="search-node" className={`${baseClass}__content`}>
                    {loading && <LoadingPanel />}
                    {letters.map(letter => (
                        <Card key={letter} className={`${baseClass}__definition`}>
                            <CardHeader>
                                <h3 id={`abbr-${letter}`} dangerouslySetInnerHTML={{ __html: letter }} className={`${baseClass}__definition__title`} />
                            </CardHeader>
                            <CardBody>
                                <dl>
                                    {filteredGlossary[letter].map(item => (
                                        <React.Fragment key={item.abbreviation}>
                                            <dt>
                                                <dfn
                                                    id={item.id}
                                                    dangerouslySetInnerHTML={{
                                                        __html: `${item.title} ${item.abbreviation !== null ? `(${item.abbreviation})` : ``}`,
                                                    }}
                                                />
                                                <Icon
                                                    inline
                                                    name="copy"
                                                    spacing="both"
                                                    size="large"
                                                    className={`${baseClass}_clipboard-icon`}
                                                    onClick={() => copyLinkToClipboard(item.id)}
                                                />
                                            </dt>
                                            <dd dangerouslySetInnerHTML={{ __html: item.definition }} />
                                        </React.Fragment>
                                    ))}
                                </dl>
                            </CardBody>
                        </Card>
                    ))}
                </div>
                <footer className={`${baseClass}__list__footer`}>
                    <Letters letters={letters} />
                </footer>
            </div>
            <BackToTop />
        </Page>
    );
};

export default Glossary;
