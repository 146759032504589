import React from "react";
import classNames from "classnames";

import { Tooltip } from "@progress/kendo-react-tooltip";

import "./ContactInfo.scss";
import Link from "../Link";
import ContactImage from "../ContactImage";

const baseClass = "acl-contact";

interface IProps {
    title?: React.ReactNode;
    fullName: string;
    email: string;
    phoneNumber: string;
    className?: string;
    contactPersonWidth?: string;
}

const defaultTitle = <>Your Contact Person</>;

const ContactInfo: React.FC<IProps> = props => {
    const { title = defaultTitle, fullName, email, phoneNumber, className, contactPersonWidth = "5rem" } = props;
    const classes: string = classNames(baseClass, className);

    return (
        <div className={classes} title="Your contact person at Acolin">
            <h3 className={`${baseClass}__headline`}>{title}</h3>
            <ContactImage fullName={fullName} email={email} width={contactPersonWidth} />
            <address className={`${baseClass}__address`}>
                <strong className={`${baseClass}__name`}>{fullName}</strong>
                <div className={`${baseClass}__actions`}>
                    <Tooltip openDelay={100} position="bottom" anchorElement="target">
                        <Link className={`${baseClass}__action`} shape="round" icon="email-action-unread" to={`mailto:${email}`} title={email}>
                            Email
                        </Link>
                    </Tooltip>
                    {phoneNumber && (
                        <Tooltip openDelay={100} position="bottom" anchorElement="target">
                            <Link className={`${baseClass}__action`} shape="round" icon="phone" to={`tel:${phoneNumber}`} title={phoneNumber}>
                                Phone number
                            </Link>
                        </Tooltip>
                    )}
                </div>
            </address>
        </div>
    );
};

export default React.memo(ContactInfo);
