import React, { useEffect, useState } from "react";
import { publish } from "pubsub-js";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Card, CardBody, CardFooter } from "@progress/kendo-react-layout";
import { Chip, Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn, GridCellProps } from "@progress/kendo-react-grid";
import { Link } from "@components/common";
import Page, { Title } from "@components/containers/Page";
import { LoadingPanel } from "@components/common";
import ResponsiveTable from "@components/common/ResponsiveTable";
import { useAppUserContext, useNotificationContext } from "@src/common/Context";
import VendorForm from "./VendorForm/VendorForm";
import { Vendor } from "./types";
import "./DataServices.scss";
import { GET_ACOLIN_SERVICES_OWNERS, GET_VENDORS } from "@src/common/graphql";
import { AcolinServiceOwner, PSChannel } from "@src/common/types";
import ContactImage from "@components/common/ContactImage";
import { errors } from "@src/common/errors";

const baseClass = "acl-page-data-services";

const VendorNameCell: React.FC<GridCellProps> = ({ dataItem }) => <td className={dataItem.vendors && `${baseClass}__vendor-group`}>{dataItem.name}</td>;

const StatusCell: React.FC<GridCellProps> = ({ dataItem, field }) => (
    <td className={`${baseClass}__status-cell`}>
        {!dataItem.vendors && (
            <Chip
                text={dataItem[field] ? "Active" : "Available"}
                value="chip"
                fillMode={"solid"}
                className={`${baseClass}__status--${dataItem[field] ? "success" : "warning"}`}
                themeColor={dataItem.isPending ? "base" : dataItem[field] ? "success" : "warning"}
            />
        )}
    </td>
);

const columns = [
    { field: "name", title: "Name", expandable: false, cell: VendorNameCell },
    { field: "active", title: "Activation Status", cell: StatusCell, width: 220 },
];

const DataServices: React.FC<{}> = () => {
    const [showModal, setShowModal] = useState(false);
    const [appUser, _setAppUser] = useAppUserContext();
    const [serviceOwner, setServiceOwner] = useState<AcolinServiceOwner>();

    const { error } = useQuery(GET_ACOLIN_SERVICES_OWNERS, {
        variables: { services: ["acolinServiceOwnerDataId"] },
        fetchPolicy: "cache-first",
        onCompleted: ({ acolinServicesOwners }) => {
            setServiceOwner(acolinServicesOwners[0]);
        },
    });

    if (error) {
        console.error("An error occurred:", error);
    }
    const [getVendors, { loading, data }] = useLazyQuery(GET_VENDORS, {
        variables: { companyId: appUser.companyId },
        fetchPolicy: "cache-first",
    });

    useEffect(() => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.api.MISSING_COMPANY_ID);
            return;
        }
        getVendors().catch(error => console.error(error));
    }, []);

    const sortVendors = (): Vendor[] => [...data?.vendors].sort((a: Vendor, b: Vendor) => a.name.localeCompare(b.name));

    return (
        <Page className={baseClass}>
            <header className={`${baseClass}__header`}>
                <Title className={`${baseClass}__title`}>Data Services</Title>
            </header>
            <div className={`${baseClass}__main`}>
                <div className={`${baseClass}__static`}>
                    <Card className={`${baseClass}__static__dm`}>
                        {loading ? (
                            <div className={`${baseClass}__loading-wrapper`}>
                                <LoadingPanel />
                            </div>
                        ) : (
                            <CardBody className={`${baseClass}__static__content`}>
                                <p>
                                    Acolin understands data, regulation, and distribution. To help our clients address the complex data management issues they
                                    are facing due to increasing regulatory pressure, Acolin has launched a premium Data Management and Distribution Service.{" "}
                                    <br />
                                    <br />
                                    Our Data Service includes data collection, validation, quality control, reporting, and controlled distribution of fund data
                                    to your selected list of vendors. Below is the list of <b> data vendors </b> that have already been or could be activated
                                    for your service and the type of data we distribute to them on your behalf.
                                </p>
                                <ResponsiveTable>
                                    <Grid data={data ? sortVendors() : []} scrollable="none">
                                        {columns.map(column => (
                                            <GridColumn key={column.title} field={column.field} {...column} />
                                        ))}
                                    </Grid>
                                </ResponsiveTable>
                                <div className={`k-form-buttons ${baseClass}__actions`}>
                                    <Button
                                        themeColor="primary"
                                        onClick={(): void => {
                                            if (!appUser.companyId) {
                                                publish(PSChannel.Error, errors.api.MISSING_COMPANY_ID);
                                                return;
                                            }
                                            setShowModal(true);
                                        }}
                                    >
                                        Change or Add Vendors
                                    </Button>
                                </div>
                            </CardBody>
                        )}
                    </Card>
                    {serviceOwner && (
                        <Card>
                            <CardBody>
                                <div className={`${baseClass}__image`}>
                                    <ContactImage fullName={serviceOwner.ownerName} email={serviceOwner.ownerEmail} />
                                </div>

                                <h3 className={`${baseClass}__name`}>{serviceOwner.ownerName}</h3>
                                <p className={`${baseClass}__title__owner`}>{serviceOwner.serviceName}</p>
                                <p className={`${baseClass}__text`}>{serviceOwner.serviceDescription}</p>
                            </CardBody>
                            <CardFooter>
                                <div className={`${baseClass}__button`}>
                                    <Link
                                        className={`${baseClass}__action`}
                                        shape="round"
                                        icon="email-action-unread"
                                        to={`mailto:${serviceOwner.ownerEmail}`}
                                        title={`${serviceOwner.ownerEmail}`}
                                    >
                                        Email
                                    </Link>
                                </div>
                            </CardFooter>
                        </Card>
                    )}
                </div>
                {showModal && <VendorForm vendors={sortVendors()} onClose={(): void => setShowModal(false)} />}
            </div>
        </Page>
    );
};

export default DataServices;
