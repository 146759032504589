import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import { Form, FormElement, Field } from "@progress/kendo-react-form";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";

import { emailValidator, passwordLengthValidator, repeatPasswordValidator } from "@src/common/util";
import { RECAPTCHA_KEY, shouldDisableCaptcha } from "@src/common/config";
import axios from "@src/common/http";
import { LabelInput, Icon } from "@components/common";

import "./Signup.scss";

const baseClass = "acl-page-auth";

const repeatNewPasswordValidator = (repeatPassword, getField): string =>
    passwordLengthValidator(repeatPassword) || repeatPasswordValidator(repeatPassword, getField("password"));

const Signup: React.FC<{}> = () => {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);
    const recaptchaRef = React.useRef(null);

    const handleSubmit = async ({ email, password }, e?: React.SyntheticEvent): Promise<void> => {
        e.preventDefault();
        setSubmitting(true);

        const recaptcha = shouldDisableCaptcha ? "" : await recaptchaRef.current.executeAsync();
        const response = await axios.post(`/portal/signup`, {
            email,
            password,
            recaptcha,
        });
        const { status, data } = response;

        if (status === 201) {
            setSubmitting(false);
            navigate("/signup-successful");
        } else {
            setSubmitting(false);
            !shouldDisableCaptcha && recaptchaRef.current.reset();
        }
    };

    return (
        <main className={`${baseClass} ${baseClass}--signup`}>
            <Form
                onSubmit={handleSubmit}
                render={({ valid, allowSubmit }): React.ReactElement => (
                    <FormElement className={`${baseClass}__form`} noValidate={true}>
                        <h1>Client Portal</h1>
                        <Card className={`${baseClass}__content`}>
                            <CardBody>
                                <h3 className={`${baseClass}__title`}>Create New Portal Account</h3>
                                <Link className={`${baseClass}__signup-link`} to="/login">
                                    Already registered?
                                </Link>
                                <Field
                                    type="email"
                                    name="email"
                                    label="Your email address"
                                    id="txtEmail"
                                    ariaDescribedBy="txtEmailError"
                                    component={LabelInput}
                                    validator={emailValidator}
                                    validityStyles={false}
                                    required={true}
                                    disabled={submitting}
                                    className={`${baseClass}__field`}
                                />
                                <div className={`${baseClass}__password-wrapper`}>
                                    <Field
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        label="Choose a password"
                                        id="txtPassword"
                                        ariaDescribedBy="txtPasswordError"
                                        component={LabelInput}
                                        validator={passwordLengthValidator}
                                        validityStyles={false}
                                        required={true}
                                        disabled={submitting}
                                        className={`${baseClass}__field`}
                                    />
                                    <Icon
                                        name={showPassword ? "view" : "view-off"}
                                        className={`${baseClass}__view-icon`}
                                        color="secondary"
                                        onClick={(): void => setShowPassword(!showPassword)}
                                    />
                                </div>

                                <div className={`${baseClass}__password-wrapper`}>
                                    <Field
                                        type={showRepeatPassword ? "text" : "password"}
                                        name="repeatPassword"
                                        label="Confirm new password"
                                        id="txtRepeatPassword"
                                        ariaDescribedBy="txtRepeatPasswordError"
                                        component={LabelInput}
                                        validator={repeatNewPasswordValidator}
                                        validityStyles={false}
                                        required={true}
                                        disabled={submitting}
                                        className={`${baseClass}__field`}
                                    />
                                    <Icon
                                        name={showRepeatPassword ? "view" : "view-off"}
                                        className={`${baseClass}__view-icon`}
                                        color="secondary"
                                        onClick={(): void => setShowRepeatPassword(!showRepeatPassword)}
                                    />
                                </div>

                                <div className={`k-form-buttons ${baseClass}__info`}>
                                    <Icon name="question-circle" />
                                    <p>You will receive an email with information about the next steps.</p>
                                </div>
                                <div className={`k-form-buttons ${baseClass}__actions`}>
                                    <Button themeColor="primary" type="submit" disabled={!allowSubmit || !valid || submitting}>
                                        {submitting && <Icon name="loading" spacing="right" />}
                                        Create New Account
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                        {!shouldDisableCaptcha && <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} badge="bottomright" size="invisible" />}
                    </FormElement>
                )}
            />
        </main>
    );
};

export default Signup;
