import React from "react";
import { Card, CardBody, CardFooter, CardHeader } from "@progress/kendo-react-layout";
import LoadingPanel from "../LoadingPanel";
import Link from "@components/common/Link";
import "./Articles.scss";

const baseClass = "acl-widget";

type NewsAndViews = {
    id: string;
    title: string;
    date: string;
    slug: string;
    url: string;
};

type IProps = {
    loading: boolean;
    data: NewsAndViews[];
    heading: string;
    url: string;
};

const Articles: React.FC<IProps> = ({ loading, data, heading, url }) => (
    <Card>
        <CardHeader>
            <h3>{heading}</h3>
        </CardHeader>
        <CardBody>
            {!loading && (
                <ul className={`${baseClass}--articles__list`}>
                    {data?.map(({ title, date, url }, index) => (
                        <li key={index}>
                            <a href={url} target="_blank">
                                <time>{date}</time>
                                <span>{title}</span>
                            </a>
                        </li>
                    ))}
                </ul>
            )}
            {loading && <LoadingPanel />}
        </CardBody>
        <CardFooter>
            <Link to={url} target="_blank" type="button" primary>
                Read more...
            </Link>
        </CardFooter>
    </Card>
);

export default Articles;
