import React, { useEffect } from "react";
import Mark from "mark.js";
import { useQuery } from "@apollo/client";

import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";

import { useStateEx as useState } from "@src/common/hooks";
import Page, { Title } from "@components/containers/Page";
import { BackToTop, LoadingPanel, FilterInput, Icon } from "@components/common";

import "../FAQs/FAQs.scss";
import { GET_USER_GUIDELINES } from "@src/common/graphql";
import { scrollHashElementIntoView } from "@src/common/util";
import { useSearchParams } from "react-router-dom";
import { useNotificationContext } from "@src/common/Context";

const baseClass = "acl-page-faqs";

type TFAQ = Record<string, Record<string, string>[]>;

export const filterData = (faqList: TFAQ, filter: string): object => {
    const lowerCaseFilter = filter.toLowerCase();
    const filtered = {};

    Object.entries(faqList).forEach(([key, value]) => {
        const newArray = [];
        value.forEach(element => {
            if (
                key.toLowerCase().includes(lowerCaseFilter) ||
                (element.question && element.question.toLowerCase().includes(lowerCaseFilter)) ||
                (element.answer && element.answer.toLowerCase().includes(lowerCaseFilter))
            ) {
                newArray.push(element);
            }
        });
        if (newArray.length) {
            filtered[key] = newArray;
        }
    });
    return filtered;
};

const UserGuidelines: React.FC<{}> = () => {
    const [filter, setFilter] = useState("");
    const [filteredUserGuidelines, setFilteredUserGuidelines] = useState({});
    const [searchParams, _setSearchParams] = useSearchParams();
    const { sendNotification } = useNotificationContext();

    const { loading, error, data } = useQuery(GET_USER_GUIDELINES, {
        fetchPolicy: "cache-first",
        onCompleted: data => {
            setFilteredUserGuidelines(data?.userGuidelines ?? {});
        },
    });

    const handleSearch = (value: string): void => setFilter(value);

    useEffect(() => {
        setFilteredUserGuidelines(filterData(data?.userGuidelines ?? {}, filter));
        setTimeout(() => {
            const markInstance = new Mark(document.getElementById("search-node"));
            markInstance.unmark({
                done: () => {
                    markInstance.mark(filter, { className: `${baseClass}__highlight` });
                },
            });
        });
    }, [filter]);

    const sections = Object.keys(filteredUserGuidelines);

    const copyLinkToClipboard = (id: number): void => {
        navigator.clipboard.writeText(`${window.location.origin}${location.pathname}?id=${id}`);
        sendNotification({
            timeout: 6000,
            type: "success",
            message: "Link is coppied to clipboard.",
        });
    };

    useEffect(() => {
        const id = searchParams.get("id");
        scrollHashElementIntoView(id);
        document.getElementById(id)?.click();
    }, [filteredUserGuidelines]);

    return (
        <Page className={baseClass}>
            <Title className={`${baseClass}__title`}>User Guidelines</Title>
            <FilterInput placeholder={"Type to filter user guidelines..."} onChange={handleSearch} />
            <div className={`${baseClass}__list`}>
                {filteredUserGuidelines && (
                    <div id="search-node">
                        {sections.map(section => (
                            <Card key={section} className={`${baseClass}__faq-section`}>
                                <CardHeader>
                                    <h3 className={`${baseClass}__faq-section__title`}>{section}</h3>
                                </CardHeader>
                                <CardBody>
                                    {filteredUserGuidelines[section].map((item, i) => (
                                        <details
                                            className={`${baseClass}__faq__group`}
                                            key={i}
                                            open={
                                                filter.length &&
                                                (item.question.toLowerCase().includes(filter.toLowerCase()) ||
                                                    item.answer.toLowerCase().includes(filter.toLowerCase()))
                                            }
                                        >
                                            <summary>
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: item.question }}
                                                    id={item.id}
                                                    className={`${baseClass}__details-title`}
                                                />
                                                <Icon
                                                    inline
                                                    name="copy"
                                                    spacing="both"
                                                    size="large"
                                                    className={`${baseClass}_clipboard-icon`}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        copyLinkToClipboard(item.id);
                                                    }}
                                                />
                                            </summary>
                                            <div className={`${baseClass}__answer`} dangerouslySetInnerHTML={{ __html: item.answer }} />
                                        </details>
                                    ))}
                                </CardBody>
                            </Card>
                        ))}
                    </div>
                )}
                {loading && <LoadingPanel />}
            </div>
            <BackToTop />
        </Page>
    );
};

export default UserGuidelines;
