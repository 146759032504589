import React, { useState, useEffect, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { pick } from "lodash";
import { format } from "date-fns";

import { MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";

import axios from "@src/common/http";
import { getCountryCode, getFilenameFromContentDisposition } from "@src/common/util";
import { API_ENDPOINT } from "@src/common/config";
import { Icon, LoadingPanel, Flag } from "@components/common";

import { Document } from "../types";
import { GroupedDocuments, ProcessedDocuments } from "./types";
import "./Documents.scss";
import { GET_FUND_DOCUMENTS_FOR_ISIN } from "@src/common/graphql";

const baseClass = "acl-share-class-documents";

type DocumentProps = {
    isin: string;
};

type SingleDocumentProps = {
    value: Document;
};

export const SingleDocument: React.FC<SingleDocumentProps> = ({ value }) => {
    const [isDownloading, setDownloading] = useState<boolean>(false);

    const handleDownload = (id: number): void => {
        setDownloading(true);

        axios
            .get(`${API_ENDPOINT}/portal/fund-document?docId=${id}`, { responseType: "blob" })
            .then(response => {
                if (response.status !== 200) {
                    setDownloading(false);
                    return;
                }
                // Get original file name from header
                const header = decodeURI(response.headers["content-disposition"]);
                const filename = getFilenameFromContentDisposition(header, "fund-document.pdf");

                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

                setDownloading(false);
            })
            .catch(error => {
                setDownloading(false);
                console.error(error);
            });
    };

    return (
        <div
            onClick={(): void => handleDownload(value.DocumentFile_ID)}
            title={format(new Date(value.AsOfDate), "yyyy-MM-dd")}
            className={`${baseClass}__link`}
        >
            <Icon name={isDownloading ? "loading" : "office-file-pdf"} spacing="right" />
            {value.Languages_Code}
        </div>
    );
};

const Documents: React.FC<DocumentProps> = ({ isin }) => {
    const [docs, setDocs] = useState<GroupedDocuments>(null);
    const [countries, setCountries] = useState<string[]>([]);
    const [languages, setLanguages] = useState<string[]>([]);
    const [types, setTypes] = useState<string[]>([]);
    const [filteredDocs, setFilteredDocs] = useState(null);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);

    const filterDocs = useCallback(
        ({ docs, countries, types, languages }: ProcessedDocuments): void => {
            const countriesFilter = selectedCountries.length ? selectedCountries : countries;
            const languagesFilter = selectedLanguages.length ? selectedLanguages : languages;
            const typesFilter = selectedTypes.length ? selectedTypes : types;

            const filteredByCountries = pick(docs, countriesFilter);
            const filtered = Object.entries(filteredByCountries).reduce((acc, [country, groups]) => {
                const filteredByTypes = Object.entries(groups).reduce((acc, [group, types]) => {
                    const filteredTypes = pick(types, typesFilter);
                    const filteredByLanguages = Object.entries(filteredTypes).reduce((acc, [type, documents]) => {
                        const filteredDocuments = documents.filter(document => languagesFilter.includes(document.Languages_Code));
                        if (filteredDocuments.length > 0) {
                            acc[type] = filteredDocuments;
                        }
                        return acc;
                    }, {});

                    if (Object.keys(filteredByLanguages).length) {
                        acc[group] = filteredByLanguages;
                    }
                    return acc;
                }, {});

                acc[country] = filteredByTypes;
                return acc;
            }, {});

            setFilteredDocs(filtered);
        },
        [selectedCountries, selectedLanguages, selectedTypes],
    );

    const { loading, error, data } = useQuery(GET_FUND_DOCUMENTS_FOR_ISIN, {
        variables: { isin },
        fetchPolicy: "cache-first",
        onCompleted: data => {
            if (!data?.fundDocumentsForIsin) {
                return;
            }
            const { docs, countries, languages, types } = data.fundDocumentsForIsin;
            setDocs(docs);
            setCountries(countries);
            setLanguages(languages);
            setTypes(types);
            filterDocs(data.fundDocumentsForIsin);
        },
    });

    const itemRender = (li, itemProps): React.ReactElement => {
        const itemChildren = (
            <span>
                <input type="checkbox" checked={itemProps.selected} onChange={(): void => {}} />
                &nbsp;{li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    };

    const handleCountryChange = (event: MultiSelectChangeEvent): void => {
        setSelectedCountries(Array.from(event.target.value));
    };

    const handleLanguageChange = (event: MultiSelectChangeEvent): void => {
        setSelectedLanguages(Array.from(event.target.value));
    };

    const handleTypeChange = (event: MultiSelectChangeEvent): void => {
        setSelectedTypes(Array.from(event.target.value));
    };

    useEffect(() => {
        filterDocs({ docs, countries, languages, types });
    }, [selectedCountries, selectedLanguages, selectedTypes]);

    return (
        <div className={`${baseClass}`}>
            {loading ? (
                <div className={`${baseClass}__loading-wrapper`}>
                    <LoadingPanel />
                </div>
            ) : (
                <Card className={`${baseClass}__card`}>
                    <CardHeader className={`${baseClass}__header`}>
                        <h3>Documents</h3>
                    </CardHeader>
                    <CardBody className={`${baseClass}__wrapper`}>
                        <div className={`${baseClass}__filters`}>
                            {countries && countries.length ? (
                                <div className={`${baseClass}__filter`}>
                                    <Label>Country</Label>
                                    <MultiSelect
                                        data={countries}
                                        itemRender={itemRender}
                                        autoClose={false}
                                        placeholder="Select countries"
                                        value={selectedCountries}
                                        onChange={handleCountryChange}
                                        tags={
                                            selectedCountries.length > 0
                                                ? [{ text: `${selectedCountries.length} countries selected`, data: [...selectedCountries] }]
                                                : []
                                        }
                                    />
                                </div>
                            ) : null}
                            {languages && languages.length ? (
                                <div className={`${baseClass}__filter`}>
                                    <Label>Language</Label>
                                    <MultiSelect
                                        data={languages}
                                        itemRender={itemRender}
                                        autoClose={false}
                                        placeholder="Select languages"
                                        value={selectedLanguages}
                                        onChange={handleLanguageChange}
                                        tags={
                                            selectedLanguages.length > 0
                                                ? [{ text: `${selectedLanguages.length} languages selected`, data: [...selectedLanguages] }]
                                                : []
                                        }
                                    />
                                </div>
                            ) : null}
                            {types && types.length ? (
                                <div className={`${baseClass}__filter`}>
                                    <Label>Type</Label>
                                    <MultiSelect
                                        data={types}
                                        itemRender={itemRender}
                                        autoClose={false}
                                        placeholder="Select types"
                                        value={selectedTypes}
                                        onChange={handleTypeChange}
                                        tags={selectedTypes.length > 0 ? [{ text: `${selectedTypes.length} types selected`, data: [...selectedTypes] }] : []}
                                    />
                                </div>
                            ) : null}
                        </div>
                        <div className={`${baseClass}__main-content`}>
                            {filteredDocs && Object.keys(filteredDocs).length ? (
                                <>
                                    {Object.keys(filteredDocs)
                                        .sort()
                                        .map(country => (
                                            <details className={`${baseClass}__item`} open={true} key={country}>
                                                <summary className={`${baseClass}__summary`}>
                                                    <h3>
                                                        <Flag country={getCountryCode(country)} spacing="right" shape="circle" />
                                                        {country}
                                                    </h3>
                                                </summary>
                                                {Object.keys(filteredDocs[country]).length > 0 ? (
                                                    <>
                                                        {Object.keys(filteredDocs[country]).map(group => (
                                                            <div className={`${baseClass}__group`} key={group}>
                                                                {Object.values(filteredDocs[country][group]).some((list: Document[]) => list.length > 0) && (
                                                                    <>
                                                                        <h4 className={`${baseClass}__group-title`}>{group}</h4>
                                                                        {Object.keys(filteredDocs[country][group]).map(
                                                                            type =>
                                                                                filteredDocs[country][group][type].length > 0 && (
                                                                                    <div className={`${baseClass}__type-wrapper`} key={type}>
                                                                                        <h5 className={`${baseClass}__type-title`}>{type}</h5>
                                                                                        <div className={`${baseClass}__type`}>
                                                                                            {filteredDocs[country][group][type].map((value, i) => (
                                                                                                <SingleDocument value={value} key={i} />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                ),
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <h4>No documents to display</h4>
                                                )}
                                            </details>
                                        ))}
                                </>
                            ) : (
                                <h4>No documents to display</h4>
                            )}
                        </div>
                    </CardBody>
                </Card>
            )}
        </div>
    );
};

export default Documents;
