import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useMutation } from "@apollo/client";

import { Form, FormElement, Field, FormRenderProps } from "@progress/kendo-react-form";
import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";
import { Chip, Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Error } from "@progress/kendo-react-labels";

import { useAppUserContext, useNotificationContext } from "@src/common/Context";
import { RECAPTCHA_KEY, emailCampaigns, shouldDisableCaptcha } from "@src/common/config";
import { SEND_MAIL_MUTATION, UPDATE_USER_INFORMATION } from "@src/common/graphql";
import { userStatusMap } from "@src/common/consts";
import Page, { Title } from "@components/containers/Page";
import { LabelInput, Icon } from "@components/common";

import ChangePassword from "./ChangePassword";
import TwoFactorAuth from "./TwoFactorAuth";
import "./Profile.scss";

const baseClass = "acl-page-profile";

const Profile: React.FC<{}> = () => {
    const recaptchaRef = React.useRef(null);
    const [appUser, setAppUser] = useAppUserContext();
    const { firstName, lastName, email, jobTitle, statusCode } = appUser;
    const { sendNotification } = useNotificationContext();

    const [updateUserInformation, { loading, error, data }] = useMutation(UPDATE_USER_INFORMATION);
    const [sendMail] = useMutation(SEND_MAIL_MUTATION, {
        onCompleted: data => {
            console.warn("Email sent.");
            !shouldDisableCaptcha && recaptchaRef.current.reset();
        },
        onError: error => {
            console.error("Email sending failed: ", error.message);
            !shouldDisableCaptcha && recaptchaRef.current.reset();
        },
    });

    async function handleSubmit(formData: FormData): Promise<void> {
        const userInfo = {
            ...formData,
            email,
        };

        const recaptcha = shouldDisableCaptcha ? "" : await recaptchaRef.current.executeAsync();
        const message = Object.keys(formData).reduce((acc, key) => {
            const label = ((key: string): string => {
                switch (key) {
                    case "firstName":
                        return "Name";
                    case "lastName":
                        return "Last Name";
                    case "email":
                        return "Email";
                    default:
                        return "Position";
                }
            })(key);
            return `${acc}\n${label}: ${formData[key]}`;
        }, "");

        const sendMailVariables = {
            recaptcha,
            campaign: emailCampaigns.Profile,
            params: {
                Company: appUser.companyName,
                Sender: appUser.email,
                Text: message,
            },
        };

        updateUserInformation({
            variables: { userInfo },
            onCompleted: data => {
                setAppUser({
                    ...appUser,
                    firstName: data?.updateUserInformation?.firstName,
                    lastName: data?.updateUserInformation?.lastName,
                    jobTitle: data?.updateUserInformation?.jobTitle,
                });
                sendNotification({
                    timeout: 6000,
                    type: "success",
                    message: "Your request has been submitted.",
                });
                sendMail({
                    variables: sendMailVariables,
                });
            },
        });
    }

    return (
        <Page className={baseClass}>
            <Title className={`${baseClass}__title`}>Profile</Title>
            <div className={`${baseClass}__static`}>
                <div>
                    <Card className={`${baseClass}__content`}>
                        <CardHeader>
                            {statusCode !== 1 ? (
                                <Tooltip openDelay={100} position="right" anchorElement="target">
                                    <h3 className={`${baseClass}__title`}>
                                        {firstName} {lastName}
                                    </h3>
                                    <Chip
                                        text={userStatusMap[statusCode][0]}
                                        value="chip"
                                        fillMode={"solid"}
                                        themeColor={userStatusMap[statusCode][1]}
                                        className={`${baseClass}__status ${baseClass}__status--${userStatusMap[statusCode][1]}`}
                                    />
                                    <Icon
                                        title={userStatusMap[statusCode][3] || userStatusMap[statusCode][2]}
                                        className={`${baseClass}__tooltip`}
                                        name="question-circle"
                                    />
                                </Tooltip>
                            ) : (
                                <h3 className={`${baseClass}__title`}>
                                    {firstName} {lastName}
                                </h3>
                            )}
                        </CardHeader>
                        <CardBody>
                            <Form
                                onSubmit={handleSubmit}
                                render={({ valid, allowSubmit }: FormRenderProps): React.ReactElement => (
                                    <FormElement className={`${baseClass}__form`} noValidate={true}>
                                        <div className={`${baseClass}__field-group`}>
                                            <Field
                                                name="firstName"
                                                label="First Name"
                                                id="txtFirstName"
                                                ariaDescribedBy="txtNameError"
                                                component={LabelInput}
                                                defaultValue={firstName}
                                                validityStyles={false}
                                                required={true}
                                                pattern={"[A-Za-z]+"}
                                                minLength={2}
                                                disabled={loading}
                                                className={`${baseClass}__field`}
                                            />
                                            <Field
                                                name="lastName"
                                                label="Family Name"
                                                id="txtLastName"
                                                ariaDescribedBy="txtLastNameError"
                                                component={LabelInput}
                                                defaultValue={lastName}
                                                validityStyles={false}
                                                required={true}
                                                disabled={loading}
                                                className={`${baseClass}__field`}
                                            />
                                        </div>
                                        <Field
                                            type="email"
                                            name="email"
                                            label="Email Address"
                                            id="txtEmail"
                                            ariaDescribedBy="txtEmailError"
                                            component={LabelInput}
                                            defaultValue={email}
                                            validityStyles={false}
                                            required={true}
                                            disabled
                                            className={`${baseClass}__field`}
                                            tooltip={`If your email address has changed, please contact your Portal Admin to request a new user under "User Management" or get in touch with your responsible Acolin contact.`}
                                        />
                                        <Field
                                            name="jobTitle"
                                            label="Position"
                                            id="txtPosition"
                                            ariaDescribedBy="txtPositionError"
                                            component={LabelInput}
                                            defaultValue={jobTitle}
                                            validityStyles={false}
                                            required={true}
                                            disabled={loading}
                                            className={`${baseClass}__field`}
                                        />
                                        <div className={`${baseClass}__footer`}>
                                            <div className={`${baseClass}__actions`}>
                                                <Button themeColor="primary" type="submit" disabled={!allowSubmit || !valid || loading}>
                                                    Request a change
                                                </Button>
                                            </div>
                                            <div className={`${baseClass}__messages`}>
                                                {loading && (
                                                    <div className={`${baseClass}__loading`} aria-busy="true">
                                                        Please wait... <Icon name="loading" spacing="left" />
                                                    </div>
                                                )}
                                                {error && <Error className={`${baseClass}__error`}>{error.message || error.graphQLErrors?.[0]?.message}</Error>}
                                            </div>
                                        </div>
                                        {!shouldDisableCaptcha && <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} badge="bottomright" size="invisible" />}
                                    </FormElement>
                                )}
                            />
                        </CardBody>
                    </Card>

                    <Card className={`${baseClass}__content`}>
                        <CardHeader>
                            <h3 className={`${baseClass}__title`}>Two-Factor Authentication</h3>
                        </CardHeader>
                        <CardBody>
                            <TwoFactorAuth />
                        </CardBody>
                    </Card>

                    <Card className={`${baseClass}__content`}>
                        <CardHeader>
                            <h3 className={`${baseClass}__title`}>Change Password</h3>
                        </CardHeader>
                        <CardBody>
                            <ChangePassword />
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Page>
    );
};

export default Profile;
