import React from "react";
import uniq from "lodash/uniq";
import xor from "lodash/xor";
import classNames from "classnames";

import { TreeListRowProps } from "@progress/kendo-react-treelist";
import { Grid, GridColumn, GridCustomCellProps, GridHeaderCellProps } from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";

import Icon from "@components/common/Icon";

import { NodeISIN } from "../types";

const baseClass = "acl-overview-report";

const sortBy = ["PR", "KIID", "PRP", "STAT", "AR", "SAR", "MR", "QR", "LEG"];
const cache = {};

const sortDocTypes = (items: NodeISIN[]): string[] => {
    const key = items[0].parentId;
    if (cache[key]) {
        return cache[key];
    }

    const allTypes: string[] = uniq(items.reduce((acc, { documents }) => [...acc, ...Object.keys(documents)], []));
    const allowed = sortBy.filter(item => allTypes.includes(item));
    const remaining = xor(allTypes, sortBy)
        .filter(item => allTypes.includes(item))
        .sort();

    cache[key] = [...allowed, ...remaining];

    return cache[key];
};

const DocumentsRow: React.FC<TreeListRowProps> = ({ dataItem, children }: TreeListRowProps) => {
    const { isExpandable, items } = dataItem;
    const className = `${baseClass}__detail-grid`;

    if (isExpandable) {
        return <tr className={`${baseClass}__tree-list-row`}>{children}</tr>;
    } else {
        const docTypes: string[] = sortDocTypes(items);
        const docLanguages: Record<string, string[]> = {};

        const collection = items.map(({ isin, documents }) => {
            const docs = docTypes.reduce((acc, docType) => {
                if (!docLanguages[docType]) {
                    docLanguages[docType] = [];
                }

                const docList = documents[docType];
                const docProps = docList
                    ? docList.reduce((acc, { code, uid, date, error, message }) => {
                          if (!docLanguages[docType].includes(code)) {
                              docLanguages[docType].push(code);
                          }

                          acc[code] = { uid, date, error, message };

                          return acc;
                      }, {})
                    : null;

                return { ...acc, [docType]: docProps };
            }, {});

            return {
                isin,
                ...docs,
            };
        });

        const DocumentsCell: React.FC<GridCustomCellProps> = ({ dataItem, field }) => {
            const languages = docLanguages[field];
            return (
                <td className={`${className}__cell ${className}__cell--${field.toLowerCase()}`}>
                    <table className={`${className}__langs`}>
                        <tbody>
                            <tr>
                                {languages.map((language, index) => {
                                    const document = dataItem[field] ? dataItem[field][language] || {} : {};
                                    const { date, error, message } = document;

                                    const classes: string = classNames(
                                        index === 0 && `${className}__cell--language`,
                                        error === 1 && `${className}__cell--warning`,
                                        error === 2 && `${className}__cell--error`,
                                    );
                                    return (
                                        <td className={classes} style={{ width: `${100 / languages.length}%` }}>
                                            <Tooltip
                                                openDelay={100}
                                                position="top"
                                                anchorElement="target"
                                                className={`${className}__tooltip`}
                                                tooltipClassName={`${className}__tooltip`}
                                                tooltipStyle={{ cursor: "pointer" }}
                                            >
                                                {date || "n/a"}
                                                {message && <Icon title={message} name="question-circle" size="small" spacing="left" />}
                                            </Tooltip>
                                        </td>
                                    );
                                })}
                            </tr>
                        </tbody>
                    </table>
                </td>
            );
        };

        const LanguageHeaderCell: React.FC<GridHeaderCellProps> = ({ title }) => {
            const languages = docLanguages[title];
            return (
                <>
                    <div>{title}</div>
                    <table className={`${className}__langs`}>
                        <tbody>
                            <tr>
                                {languages.map(language => (
                                    <td style={{ width: `${100 / languages.length}%` }}>{language}</td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </>
            );
        };

        return (
            <tr className={`${baseClass}__tree-list__row`}>
                <td className={`${baseClass}__tree-list__cell ${baseClass}__tree-list__cell--documents`}>
                    <Grid scrollable="none" data={collection} className={className}>
                        <GridColumn field="isin" title="ISIN" className={`${className}__cell`} headerClassName={`${className}__cell`} />
                        {docTypes.map(docType => (
                            <GridColumn
                                key={docType}
                                field={docType}
                                title={docType}
                                className={`${className}__cell ${className}__cell--${docType.toLowerCase()}`}
                                headerCell={LanguageHeaderCell}
                                headerClassName={`${className}__cell ${className}__cell--document`}
                                cell={DocumentsCell}
                            />
                        ))}
                    </Grid>
                </td>
            </tr>
        );
    }
};

export default DocumentsRow;
