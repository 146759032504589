import React from "react";

import { Card } from "@progress/kendo-react-layout";

import { useAppUserContext } from "@src/common/Context";
import Page, { Title } from "@components/containers/Page";
import ContactInfo from "@components/common/ContactInfo";

import "./PointsOfContact.scss";

const baseClass = "acl-page-points-of-contact";

type PointOfContact = { title: string; key: string };

const pocCategories: PointOfContact[] = [
    { title: "Business Development", key: "businessDevelopment" },
    { title: "Client Service", key: "crm" },
    { title: "Regulatory Switzerland", key: "regulatoryRLS" },
    { title: "Regulatory Europe", key: "regulatoryGFR" },
    { title: "Data Management", key: "data" },
    { title: "Legal", key: "legal" },
    { title: "Compliance", key: "compliance" },
    { title: "Finance", key: "finance" },
    { title: "Portal Support", key: "it" },
];

const PointsOfContact: React.FC<{}> = () => {
    const [{ pointsOfContact }] = useAppUserContext();

    return (
        <Page className={baseClass}>
            <Title>Acolin Points of Contact</Title>
            <div className={`${baseClass}__cards`}>
                {pocCategories.map(({ title, key }) => {
                    if (pointsOfContact[key]) {
                        return (
                            <Card key={key} className={`${baseClass}__card`}>
                                <ContactInfo title={title} {...pointsOfContact[key]} contactPersonWidth="8rem" />
                            </Card>
                        );
                    } else {
                        return null;
                    }
                })}
            </div>
        </Page>
    );
};

export default PointsOfContact;
