import React from "react";

import { Input } from "@progress/kendo-react-inputs";
import { Label, Error } from "@progress/kendo-react-labels";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { FieldWrapper, FieldRenderProps } from "@progress/kendo-react-form";

import Icon from "@components/common/Icon";

import "./LabelInput.scss";

const baseClass = "acl-label-input";

const LabelInput: React.FC<{}> = (fieldRenderProps: FieldRenderProps) => {
    const { type, validationMessage, visited, label, id, valid, disabled, ariaDescribedBy, tooltip, ...others } = fieldRenderProps;
    const showValidationMessage = visited && validationMessage;

    return (
        <FieldWrapper>
            <Tooltip openDelay={100} position="right" anchorElement="target">
                <Label editorId={id} editorValid={valid}>
                    {label}
                    {tooltip && <Icon title={tooltip} className={`${baseClass}__tooltip`} name="question-circle" />}
                </Label>
            </Tooltip>
            <Input type={type} id={id} valid={valid} disabled={disabled} ariaDescribedBy={ariaDescribedBy} {...others} />
            {showValidationMessage && <Error id={ariaDescribedBy}>{validationMessage}</Error>}
        </FieldWrapper>
    );
};

export default LabelInput;
