import React, { useState, useEffect, useMemo, useCallback } from "react";
import { publish } from "pubsub-js";
import { useLazyQuery, useQuery } from "@apollo/client";
import classNames from "classnames";

import { Label } from "@progress/kendo-react-labels";
import { DropDownList, DropDownListChangeEvent, MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";
import { Button, Chip } from "@progress/kendo-react-buttons";

import { useAppUserContext } from "@src/common/Context";
import Page, { Title } from "@components/containers/Page";
import { BackToTop, LoadingPanel, ExportExcelTable, Icon } from "@components/common";

import Chart from "react-apexcharts";

import "./DistributionDashboard.scss";
import { ChartInputData, Color, Fee, FeeCHF, FeeData, GraphData, YearMonthQuarter, getSeriesAndOptions, getUTCYearAndMonth, getYearMonthRange } from "./util";
import { DashboardData, FilterOption, FilterOptions, PSChannel, TableColumnNumberFormat, TableColumnType, TableHeader } from "@src/common/types";
import { GET_DISTRIBUTION_DASHBOARD, GET_DISTRIBUTION_DASHBOARD_TREND } from "@src/common/graphql";
import { errors } from "@src/common/errors";
import { ProgressBar } from "@progress/kendo-react-progressbars";

const baseClass = "acl-page-distribution-dashboard";

export const EmptyCard: React.FC<{ appUser }> = ({ appUser }) => (
    <Card className={classNames(`${baseClass}__list`, `${baseClass}__list-placeholder`)}>
        <CardBody>
            <p className={`${baseClass}__empty`}>You have not signed up to Acolin’s Distribution Network Management service.</p>
            <p>
                To find out more, please visit our{" "}
                <a href="https://acolin.com" target="_blank">
                    website
                </a>{" "}
                or contact your Acolin representative {appUser.pointsOfContact.crm?.fullName} at{" "}
                <a href={`mailto:${appUser.pointsOfContact.crm?.email || "crmteam@acolin.com"}`}>
                    {appUser.pointsOfContact.crm?.email || "crmteam@acolin.com"}
                </a>
                .
            </p>
        </CardBody>
    </Card>
);
const MISSING_VALUE = "[N/A]";

export const monthToQuarter = (yearMonth: string): string => {
    const [year, month] = yearMonth.split("-");
    return `${year} Q${Math.ceil(Number(month) / 3)}`;
};

export const quarterToMonth = (quarter: string, monthNumber: 1 | 2 | 3): string => {
    const [year, quarterString] = quarter.split(" ");
    const quarterNumber = Number(quarterString[1]);
    const lastMonthNumber = quarterNumber * 3;
    const month = lastMonthNumber - (3 - monthNumber);
    const monthString = String(month).padStart(2, "0");
    return `${year}-${monthString}`;
};

const sumFeesProperty = (fees: Fee[], prop: keyof FeeCHF): number =>
    fees?.reduce((acc: number, fee: Fee): number => (isNaN(fee[prop]) ? acc : acc + fee[prop]), 0) ?? 0;

export const getInitialDateFilters = (dates: string[]): { from: string; to: string } => {
    const sortedDates = [...dates].sort();
    const lastDateIndex = sortedDates.length - 1;
    const firstDateIndex = sortedDates.length - 12;
    return {
        from: sortedDates[firstDateIndex < 0 ? 0 : firstDateIndex],
        to: sortedDates[lastDateIndex],
    };
};

const initialFilterOptions: FilterOptions = {
    assetClassList: [],
    fundList: [],
    subFundList: [],
    isinList: [],
    distributorCountryList: [],
    investorCountryList: [],
    distributorTypeList: [],
    distributorList: [],
    investorList: [],
    feeTypeList: ["Trailer Fee"],
};

const initialOptionStack: FilterOption[] = [FilterOption.FeeType];

export const getFilterOptionLabel = (option: FilterOption): string => {
    switch (option) {
        case FilterOption.AssetClass:
            return "Asset Class";
        case FilterOption.Distributor:
            return "Distributor";
        case FilterOption.DistributorCountry:
            return "Distributor Country";
        case FilterOption.DistributorType:
            return "Distributor Type";
        case FilterOption.Fund:
            return "Umbrella Fund";
        case FilterOption.Investor:
            return "Asset Class";
        case FilterOption.InvestorCountry:
            return "Investor Country";
        case FilterOption.Isin:
            return "ISIN";
        case FilterOption.SubFund:
            return "Single Fund / Subfund";
        case FilterOption.FeeType:
            return "Fee Type";
        default:
            return "";
    }
};

const getAllFees = (feeData: FeeData): Fee[] =>
    Object.values(feeData ?? {}).flatMap(item => [...(item.TF ?? []), ...(item.TFA ?? []), ...(item.FR ?? []), ...(item.FRA ?? [])]);

const getFilterOptions = (feeData: FeeData, filterFunction?: (fee: Fee) => boolean): FilterOptions => {
    const result = getAllFees(feeData)
        .filter(fee => (filterFunction ? filterFunction(fee) : true))
        .reduce(
            (acc, fee: Fee) => {
                const {
                    efamaEfcMainCategory: assetClass,
                    fundName,
                    subFundName,
                    isin,
                    distributorCountry,
                    investorCountry,
                    distributorType,
                    distributorName,
                    investorName,
                    feeType,
                } = fee;

                acc.assetClassList.add(assetClass ?? MISSING_VALUE);
                acc.fundList.add(fundName ?? MISSING_VALUE);
                acc.subFundList.add(subFundName ?? MISSING_VALUE);
                acc.isinList.add(isin ?? MISSING_VALUE);
                acc.distributorCountryList.add(distributorCountry ?? MISSING_VALUE);
                acc.investorCountryList.add(investorCountry ?? MISSING_VALUE);
                acc.distributorTypeList.add(distributorType ?? MISSING_VALUE);
                acc.distributorList.add(distributorName ?? MISSING_VALUE);
                acc.investorList.add(investorName ?? MISSING_VALUE);
                acc.feeTypeList.add(feeType ?? MISSING_VALUE);

                return acc;
            },
            {
                assetClassList: new Set<string>(),
                fundList: new Set<string>(),
                subFundList: new Set<string>(),
                isinList: new Set<string>(),
                distributorCountryList: new Set<string>(),
                investorCountryList: new Set<string>(),
                distributorTypeList: new Set<string>(),
                distributorList: new Set<string>(),
                investorList: new Set<string>(),
                feeTypeList: new Set<string>(),
            },
        );
    const {
        assetClassList,
        fundList,
        subFundList,
        isinList,
        distributorCountryList,
        investorCountryList,
        distributorTypeList,
        distributorList,
        investorList,
        feeTypeList,
    } = result;
    return {
        assetClassList: Array.from(assetClassList).sort(),
        fundList: Array.from(fundList).sort(),
        subFundList: Array.from(subFundList).sort(),
        isinList: Array.from(isinList).sort(),
        distributorCountryList: Array.from(distributorCountryList).sort(),
        investorCountryList: Array.from(investorCountryList).sort(),
        distributorTypeList: Array.from(distributorTypeList).sort(),
        distributorList: Array.from(distributorList).sort(),
        investorList: Array.from(investorList).sort(),
        feeTypeList: Array.from(feeTypeList).sort(),
    };
};

const filterFee = (fee: Fee, selectedFilters: FilterOptions): boolean => {
    const {
        efamaEfcMainCategory: assetClass,
        fundName,
        subFundName,
        isin,
        distributorCountry,
        investorCountry,
        distributorType,
        distributorName,
        investorName,
        feeType,
    } = fee;
    const {
        assetClassList,
        fundList,
        subFundList,
        isinList,
        distributorCountryList,
        investorCountryList,
        distributorTypeList,
        distributorList,
        investorList,
        feeTypeList,
    } = selectedFilters;

    const assetClassFilter = assetClassList.length > 0 ? assetClassList.includes(assetClass ?? MISSING_VALUE) : true;
    const fundFilter = fundList.length > 0 ? fundList.includes(fundName ?? MISSING_VALUE) : true;
    const subFundFilter = subFundList.length > 0 ? subFundList.includes(subFundName ?? MISSING_VALUE) : true;
    const isinFilter = isinList.length > 0 ? isinList.includes(isin ?? MISSING_VALUE) : true;
    const distributorCountryFilter = distributorCountryList.length > 0 ? distributorCountryList.includes(distributorCountry ?? MISSING_VALUE) : true;
    const investorCountryFilter = investorCountryList.length > 0 ? investorCountryList.includes(investorCountry ?? MISSING_VALUE) : true;
    const distributorTypeFilter = distributorTypeList.length > 0 ? distributorTypeList.includes(distributorType ?? MISSING_VALUE) : true;
    const distributorFilter = distributorList.length > 0 ? distributorList.includes(distributorName ?? MISSING_VALUE) : true;
    const investorFilter = investorList.length > 0 ? investorList.includes(investorName ?? MISSING_VALUE) : true;
    const feeTypeFilter = feeTypeList.length > 0 ? feeTypeList.includes(feeType ?? MISSING_VALUE) : true;

    return (
        assetClassFilter &&
        fundFilter &&
        subFundFilter &&
        isinFilter &&
        distributorCountryFilter &&
        investorCountryFilter &&
        distributorTypeFilter &&
        distributorFilter &&
        investorFilter &&
        feeTypeFilter
    );
};

const columns: TableHeader<Fee>[] = [
    { label: "Retro ID", field: "retroId", type: TableColumnType.STRING, exportColumnWidth: 20 },
    { label: "ISIN", field: "isin", type: TableColumnType.STRING, exportColumnWidth: 20 },
    { label: "Full Share Class Name", field: "fullShareClassName", type: TableColumnType.STRING, exportColumnWidth: 80 },
    { label: "Single Fund / Subfund", field: "subFundName", type: TableColumnType.STRING, exportColumnWidth: 80 },
    { label: "Umbrella Fund", field: "fundName", type: TableColumnType.STRING, exportColumnWidth: 80 },
    { label: "Asset Class", field: "efamaEfcMainCategory", type: TableColumnType.STRING, exportColumnWidth: 20 },
    { label: "Distributor", field: "distributorName", type: TableColumnType.STRING, exportColumnWidth: 50 },
    { label: "Distributor Country", field: "distributorCountry", type: TableColumnType.STRING, exportColumnWidth: 20 },
    { label: "Distributor Type", field: "distributorType", type: TableColumnType.STRING, exportColumnWidth: 20 },
    { label: "Investor", field: "investorName", type: TableColumnType.STRING, exportColumnWidth: 50 },
    { label: "Investor Country", field: "investorCountry", type: TableColumnType.STRING, exportColumnWidth: 20 },
    { label: "Date", field: "startDate", type: TableColumnType.DATE, exportColumnWidth: 20 },
    { label: "Fee Type", field: "feeType", type: TableColumnType.STRING, exportColumnWidth: 30 },
    { label: "CP Name", field: "cpName", type: TableColumnType.STRING, exportColumnWidth: 50 },
    { label: "CP MERLIN ID", field: "cpMerlinId", type: TableColumnType.STRING, exportColumnWidth: 20 },
    { label: "Contract Type", field: "contractType", type: TableColumnType.STRING, exportColumnWidth: 50 },
    { label: "Fund Group", field: "fundGroup", type: TableColumnType.STRING, exportColumnWidth: 20 },
    { label: "Fee in CHF", field: "feeInChf", type: TableColumnType.NUMBER, exportNumberFormat: TableColumnNumberFormat.LONG, exportColumnWidth: 20 },
    { label: "AUS in CHF", field: "ausInChf", type: TableColumnType.NUMBER, exportNumberFormat: TableColumnNumberFormat.LONG, exportColumnWidth: 20 },
];

type LoadingProgress = {
    total: number;
    progress: number;
};

const DistributionDashboard: React.FC<{}> = () => {
    const [appUser, _setAppUser] = useAppUserContext();
    const [showNetwork, setShowNetwork] = useState<boolean>(true);
    const [loadingDashboard, setLoadingDashboard] = useState<boolean>(true);

    const [isQuarterDates, setIsQuarterDates] = useState<boolean>(false);

    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [allFilterOptions, setAllFilterOptions] = useState<FilterOptions>(initialFilterOptions);

    const [optionStack, setOptionStack] = useState<FilterOption[]>(initialOptionStack);

    const isInOptionStack = useCallback((option: FilterOption) => optionStack.includes(option), [optionStack]);
    const isLastInOptionStack = useCallback((option: FilterOption) => optionStack.length > 0 && optionStack[optionStack.length - 1] === option, [optionStack]);
    const deleteFromStack = useCallback((option: FilterOption) => {
        setOptionStack(prev => {
            const optionToRemoveIndex = prev.findIndex(item => item === option);
            if (optionToRemoveIndex !== -1) {
                return prev.filter((item, index) => index !== optionToRemoveIndex);
            }
            return prev;
        });
    }, []);

    const pushToOptionStack = useCallback((option: FilterOption) => {
        setOptionStack(prev => [...prev, option]);
    }, []);

    const popFromOptionStack = useCallback(() => {
        setOptionStack(prev => {
            if (prev.length <= 1) {
                return [];
            } else {
                return prev.slice(0, prev.length - 1);
            }
        });
    }, []);

    const [allFeeData, setAllFeeData] = useState<FeeData>(null);

    const [trendFeeData, setTrendFeeData] = useState<FeeData>(null);

    const [selectedFilterOptions, setSelectedFilterOptions] = useState<FilterOptions>(initialFilterOptions);

    const [loadingProgress, setLoadingProgress] = useState<LoadingProgress>({ total: null, progress: null });

    const currentFilterOptions: FilterOptions = useMemo(() => {
        if (optionStack.length === 0) {
            return allFilterOptions;
        } else if (optionStack.length === 1) {
            const option = optionStack[0];
            const unfilteredOption = allFilterOptions[option];
            const filteredOptions = getFilterOptions(allFeeData, fee => filterFee(fee, selectedFilterOptions));
            return { ...filteredOptions, [option]: unfilteredOption };
        } else {
            const lockedOptions = optionStack.slice(0, optionStack.length - 1);
            const lastOption = optionStack[optionStack.length - 1];
            const filteredOptions = getFilterOptions(allFeeData, fee => filterFee(fee, selectedFilterOptions));
            const partialFilterOptions = getFilterOptions(allFeeData, fee => filterFee(fee, { ...selectedFilterOptions, [lastOption]: [] }));
            return lockedOptions.reduce(
                (acc, option) => {
                    acc[option] = selectedFilterOptions[option];
                    return acc;
                },
                { ...filteredOptions, [lastOption]: partialFilterOptions[lastOption] },
            );
        }
    }, [allFilterOptions, selectedFilterOptions, optionStack, allFeeData]);

    const updateFilter = useCallback(
        (option: FilterOption, newSelectedOptions: string[]) => {
            const areNewOptionsEmpty = newSelectedOptions.length === 0;
            if (isInOptionStack(option)) {
                if (isLastInOptionStack(option)) {
                    if (areNewOptionsEmpty) {
                        popFromOptionStack();
                    } else {
                    }
                    setSelectedFilterOptions(prev => ({ ...prev, [option]: newSelectedOptions }));
                } else {
                    if (areNewOptionsEmpty) {
                        deleteFromStack(option);
                        setSelectedFilterOptions(prev => ({ ...prev, [option]: newSelectedOptions }));
                    }
                }
            } else {
                pushToOptionStack(option);
                setSelectedFilterOptions(prev => ({ ...prev, [option]: newSelectedOptions }));
            }
        },
        [isInOptionStack, isLastInOptionStack, pushToOptionStack, deleteFromStack],
    );

    const [selectedFromDate, setSelectedFromDate] = useState<string>("");
    const [selectedToDate, setSelectedToDate] = useState<string>("");

    const [getDistributionDashboard, { loading, error, data }] = useLazyQuery(GET_DISTRIBUTION_DASHBOARD, {
        fetchPolicy: "cache-first",
        onCompleted: data => {
            if (!data) {
                return;
            }
            const { fees } = data;
            if (fees && fees.fees) {
                setAllFeeData(prevState => {
                    if (prevState) {
                        return { ...prevState, ...fees.fees };
                    } else {
                        return fees.fees;
                    }
                });
            }
        },
    });

    const [getTrendData, { loading: loadingTrend, error: errorTrend, data: dataTrend }] = useLazyQuery(GET_DISTRIBUTION_DASHBOARD_TREND, {
        variables: { view: "TF-TREND" },
        fetchPolicy: "cache-first",
        onCompleted: data => {
            if (!data) {
                return;
            }
            const { fees } = data;
            if (fees && fees.fees) {
                setTrendFeeData(fees.fees);
            }
        },
    });

    const getDistributionDashboardChunked = useCallback(async (): Promise<void> => {
        const today: YearMonthQuarter = getUTCYearAndMonth(new Date());
        const { year: currentYear, month: currentMonth } = today;
        const { data } = await getDistributionDashboard({ variables: { year: currentYear, month: currentMonth, includeDateRange: true } });
        const { min, max } = data?.fees?.dateRange || { min: null, max: null };
        if (!min || !max) {
            return;
        }
        const startDate = new Date(min);
        const endDate = new Date(max);

        const start: YearMonthQuarter = getUTCYearAndMonth(startDate);
        const end: YearMonthQuarter = getUTCYearAndMonth(endDate);
        const monthRange: YearMonthQuarter[] = getYearMonthRange(start, end);

        const includedQuarters = new Set<string>();
        const quarterRange = monthRange.filter(yearMonthQuarter => {
            const { quarter, year } = yearMonthQuarter;
            const quarterYear = `Q${quarter}${year}`;
            if (!includedQuarters.has(quarterYear)) {
                includedQuarters.add(quarterYear);
                return true;
            }
            return false;
        });

        setLoadingProgress({ total: quarterRange.length, progress: 0 });

        if (quarterRange.length > 0) {
            for (const { year, quarter } of quarterRange) {
                await getDistributionDashboard({ variables: { year, quarter } });
                setLoadingProgress(prevState => ({ ...prevState, progress: prevState.progress + 1 }));
            }
        }
    }, [getDistributionDashboard]);

    useEffect(() => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.api.MISSING_COMPANY_ID);
            return;
        }
        getDistributionDashboardChunked()
            .catch(error => console.error(error))
            .finally(() => {
                setLoadingDashboard(false);
                getTrendData().catch(error => console.error(error));
            });
    }, []);

    useEffect(() => {
        const allDates = Object.keys(allFeeData ?? {});
        if (allDates.length === 0) {
            return;
        } else {
            const { from, to } = getInitialDateFilters(allDates);
            setSelectedFromDate(from);
            setSelectedToDate(to);
        }

        const filterOptions = getFilterOptions(allFeeData);
        setAllFilterOptions(filterOptions);
    }, [allFeeData]);

    const dates = useMemo(() => Object.keys(allFeeData ?? {})?.sort((first, second) => first.localeCompare(second)), [allFeeData]);
    const fromDates = useMemo(() => {
        const filteredDates = dates.filter(date => date <= selectedToDate);
        return [...filteredDates].sort((first, second) => first.localeCompare(second));
    }, [dates, selectedToDate]);
    const toDates = useMemo(() => {
        const filteredDates = dates.filter(date => date >= selectedFromDate);
        return [...filteredDates].sort((first, second) => second.localeCompare(first));
    }, [dates, selectedFromDate]);
    const filteredDates = useMemo(() => dates.filter(date => date >= selectedFromDate && date <= selectedToDate), [dates, selectedFromDate, selectedToDate]);

    const selectedToDateQuarter = useMemo(() => monthToQuarter(selectedToDate), [selectedToDate]);
    const selectedFromDateQuarter = useMemo(() => monthToQuarter(selectedFromDate), [selectedFromDate]);
    const toDatesQuarter = useMemo(() => {
        const quarters = [...new Set(toDates.map(date => monthToQuarter(date)))];
        return [...quarters].sort((first, second) => second.localeCompare(first));
    }, [toDates]);
    const fromDatesQuarter = useMemo(() => {
        const quarters = [...new Set(fromDates.map(date => monthToQuarter(date)))];
        return [...quarters].sort((first, second) => first.localeCompare(second));
    }, [fromDates]);

    const toggleIsQuarterDates = useCallback(() => {
        setIsQuarterDates(prev => !prev);
        setSelectedFromDate(prev => {
            const quarter = monthToQuarter(prev);
            const firstMonth = quarterToMonth(quarter, 1);
            return firstMonth;
        });
        setSelectedToDate(prev => {
            const quarter = monthToQuarter(prev);
            const lastMonth = quarterToMonth(quarter, 3);
            return lastMonth;
        });
    }, []);

    const onFromDateChange = useCallback(
        (e: DropDownListChangeEvent): void => {
            const value: string = e.target.value;
            if (isQuarterDates) {
                const firstMonth = quarterToMonth(value, 1);
                setSelectedFromDate(firstMonth);
            } else {
                setSelectedFromDate(value);
            }
        },
        [isQuarterDates],
    );
    const onToDateChange = useCallback(
        (e: DropDownListChangeEvent): void => {
            const value: string = e.target.value;
            if (isQuarterDates) {
                const lastMonth = quarterToMonth(value, 3);
                setSelectedToDate(lastMonth);
            } else {
                setSelectedToDate(value);
            }
        },
        [isQuarterDates],
    );

    const fees: DashboardData = useMemo(() => {
        if (dates.length === 0 || !allFeeData) {
            // TF_AUS represents relation between Trailer Fees and AUS sums in aunal bps
            return { TF: {}, TF_ADJUSTED: {}, AUS: {}, TF_AUS: {}, TF_TREND: {}, FR: {}, FRA: {} };
        }
        return filteredDates.reduce(
            (acc, date) => {
                const filteredTF = allFeeData[date].TF?.filter(item => filterFee(item, selectedFilterOptions));
                const filteredTFAdjusted = allFeeData[date].TFA?.filter(item => filterFee(item, selectedFilterOptions));
                const filteredFR = allFeeData[date].FR?.filter(item => filterFee(item, selectedFilterOptions));
                const filteredFRA = allFeeData[date].FRA?.filter(item => filterFee(item, selectedFilterOptions));

                const trendFilterOptions: (keyof FilterOptions)[] = ["distributorTypeList", "distributorCountryList", "investorCountryList", "feeTypeList"];
                const selectedTrendFilterOptions: FilterOptions = trendFilterOptions.reduce(
                    (acc, option) => {
                        acc[option] = selectedFilterOptions[option];
                        return acc;
                    },
                    { ...initialFilterOptions },
                );
                const filteredTFTrend = trendFeeData?.[date]?.TF?.filter(item => filterFee(item, selectedTrendFilterOptions));

                const TF = sumFeesProperty(filteredTF, "feeInChf");
                const TF_ADJUSTED = sumFeesProperty(filteredTFAdjusted, "feeInChf");
                const FR = sumFeesProperty(filteredFR, "feeInChf");
                const FRA = sumFeesProperty(filteredFRA, "feeInChf");

                const ausIgnoredContractType = "3rd Party Marketer Agreement (DP)";
                const AUS = sumFeesProperty(
                    filteredTF?.filter(item => item.contractType !== ausIgnoredContractType),
                    "ausInChf",
                );

                const TF_NETWORK = sumFeesProperty(filteredTFTrend, "feeInChf");
                const AUS_NETWORK = sumFeesProperty(
                    filteredTFTrend?.filter(item => item.contractType !== ausIgnoredContractType),
                    "ausInChf",
                );

                acc.TF[date] = TF ?? 0;
                acc.TF_ADJUSTED[date] = TF_ADJUSTED ?? 0;
                acc.FR[date] = FR ?? 0;
                acc.FRA[date] = FRA ?? 0;
                acc.AUS[date] = AUS ?? 0;
                acc.TF_AUS[date] = AUS ? (TF * 10000 * 12) / AUS : 0;

                acc.TF_TREND[date] = AUS_NETWORK ? (TF_NETWORK * 10000 * 12) / AUS_NETWORK : 0;

                return acc;
            },
            { TF: {}, TF_ADJUSTED: {}, AUS: {}, TF_AUS: {}, TF_TREND: {}, FR: {}, FRA: {} },
        );
    }, [filteredDates, allFeeData, selectedFilterOptions, trendFeeData]);

    const feesView: DashboardData = useMemo(() => {
        if (!isQuarterDates) {
            return fees;
        }

        return Object.entries(fees).reduce((acc: DashboardData, [feeType, sumOnDate]) => {
            acc[feeType] = Object.entries(sumOnDate).reduce((acc, [date, sum]) => {
                const averageValueFees: (keyof DashboardData)[] = ["AUS", "TF_AUS", "TF_TREND"];
                const shouldCalculateAverage = averageValueFees.includes(feeType as keyof DashboardData);
                const quarter = monthToQuarter(date);
                const previousValue = acc[quarter] ?? 0;
                acc[quarter] = previousValue + (shouldCalculateAverage ? sum / 3 : sum);
                return acc;
            }, {});
            return acc;
        }, {});
    }, [fees, isQuarterDates]);

    const datesView = useMemo(() => {
        if (!isQuarterDates) {
            return filteredDates;
        }
        const quarters = [...new Set(filteredDates.map(date => monthToQuarter(date)))];
        return [...quarters].sort();
    }, [filteredDates, isQuarterDates]);

    const combinedChartData: ChartInputData = useMemo(() => {
        const bars: GraphData[] = [
            { name: "Trailer Fees", values: feesView.TF, style: { color: Color.acolinOrange, opacity: 0.75, stroke: 2 }, type: "bar" },
            {
                name: "Trailer Fees Adjustments",
                values: feesView.TF_ADJUSTED,
                style: { color: Color.acolinOrangeLight, opacity: 0.65, stroke: 2 },
                type: "bar",
            },
            { name: "Final Remuneration", values: feesView.FR, style: { color: Color.acolinGreen, opacity: 0.75, stroke: 2 }, type: "bar" },
            {
                name: "Final Remuneration Adjustments",
                values: feesView.FRA,
                style: { color: Color.acolinGreenLight, opacity: 0.65, stroke: 2 },
                type: "bar",
            },
        ];
        const lines: GraphData[] = [
            {
                name: "AUS",
                title: "Assets under Service in CHF",
                values: feesView.AUS,
                style: { color: Color.black, opacity: 1, stroke: 3, dash: 10 },
                type: "line",
            },
            { name: "Average bps", values: feesView.TF_AUS, style: { color: Color.blue, opacity: 0, stroke: 3 }, type: "area" },
        ];
        if (trendFeeData && showNetwork) {
            lines.push({ name: "Network bps", values: feesView.TF_TREND, style: { color: Color.blueLight, opacity: 1, stroke: 3 }, type: "line" });
        }
        return {
            bars,
            lines,
        };
    }, [feesView, trendFeeData, showNetwork]);

    const { series: combinedSeries, options: combinedOptions } = useMemo(
        () =>
            getSeriesAndOptions(combinedChartData, datesView, {
                barSeriesLabel: "Commissions in CHF (Bars)",
                lineSeriesLabel: "In Bp of AuS (Lines)",
                barSeriesDecimals: 0,
                lineSeriesDecimals: 2,
                isXAxisDatetime: !isQuarterDates,
            }),
        [combinedChartData, datesView, isQuarterDates],
    );

    const resetFilter = useCallback(() => {
        setOptionStack(initialOptionStack);
        setSelectedFilterOptions(initialFilterOptions);
        if (dates.length > 0) {
            const { from, to } = getInitialDateFilters(dates);
            setSelectedFromDate(from);
            setSelectedToDate(to);
        }
    }, [dates]);

    const exportData: Fee[] = useMemo(() => {
        if (!allFeeData) {
            return [];
        }
        const feeDataFilteredByDates = filteredDates.reduce((acc, date) => {
            acc[date] = allFeeData[date];
            return acc;
        }, {});
        return getAllFees(feeDataFilteredByDates).filter(fee => filterFee(fee, selectedFilterOptions));
    }, [allFeeData, selectedFilterOptions, filteredDates]);

    return (
        <Page className={baseClass}>
            <header className={`${baseClass}__header`}>
                <Title className={`${baseClass}__title`}>Distribution Dashboard</Title>
            </header>

            <div className={`${baseClass}__main`}>
                {loadingDashboard ? (
                    <Card className={`${baseClass}__list`}>
                        <div className={`${baseClass}__loading-wrapper`}>
                            <LoadingPanel />
                            <ProgressBar min={0} max={loadingProgress.total} value={loadingProgress.progress} labelVisible={false} />
                        </div>
                    </Card>
                ) : !data ? (
                    <EmptyCard appUser={appUser} />
                ) : (
                    <Card className={`${baseClass}__list`}>
                        <CardHeader>
                            <div className={`${baseClass}__filterGroupBetween`}>
                                <div className={`${baseClass}__filterLeft`}>
                                    <Button themeColor="primary" onClick={(): void => setShowFilters(prev => !prev)} style={{ marginRight: "30px" }}>
                                        {showFilters ? "Hide Filters" : "Show Filters"}
                                    </Button>
                                    <Button
                                        themeColor="primary"
                                        disabled={loadingTrend}
                                        onClick={(): void => setShowNetwork(prev => !prev)}
                                        style={{ marginRight: "30px" }}
                                    >
                                        {loadingTrend ? (
                                            <>
                                                <Icon name="loading" spacing="right" /> Loading Network
                                            </>
                                        ) : (
                                            <>{showNetwork ? "Hide Network" : "Show Network"}</>
                                        )}
                                    </Button>
                                </div>
                                <div className={`${baseClass}__filterRight`}>
                                    {/* @TODO Check if filename is still good */}
                                    <ExportExcelTable columns={columns} data={exportData} filename="Distributed Assets Dashboard" />
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div style={{ paddingBottom: "30px", display: "flex" }}>
                                {optionStack.map((item, index) => (
                                    <Chip key={index} style={{ marginLeft: "15px", cursor: "default" }} selected={index === optionStack.length - 1}>
                                        {getFilterOptionLabel(item)}
                                    </Chip>
                                ))}
                            </div>

                            {showFilters && (
                                <>
                                    <div className={`${baseClass}__filters`}>
                                        <div className={`${baseClass}__filterGroup`}>
                                            <div className={`${baseClass}__filter`}>
                                                <Label>Asset Class</Label>
                                                <MultiSelect
                                                    className={`${baseClass}__input-big`}
                                                    data={currentFilterOptions.assetClassList}
                                                    value={selectedFilterOptions.assetClassList}
                                                    placeholder="Select asset classes"
                                                    autoClose={false}
                                                    onChange={(event: MultiSelectChangeEvent): void => updateFilter(FilterOption.AssetClass, event.value)}
                                                    tags={
                                                        selectedFilterOptions.assetClassList.length > 0
                                                            ? [
                                                                  {
                                                                      text: `${selectedFilterOptions.assetClassList.length} selected`,
                                                                      data: [...selectedFilterOptions.assetClassList],
                                                                  },
                                                              ]
                                                            : []
                                                    }
                                                />
                                            </div>
                                            <div className={`${baseClass}__filter`}>
                                                <Label>Umbrella Fund</Label>
                                                <MultiSelect
                                                    className={`${baseClass}__input-big`}
                                                    data={currentFilterOptions.fundList}
                                                    value={selectedFilterOptions.fundList}
                                                    placeholder="Select umbrella funds"
                                                    autoClose={false}
                                                    onChange={(event: MultiSelectChangeEvent): void => updateFilter(FilterOption.Fund, event.value)}
                                                    tags={
                                                        selectedFilterOptions.fundList.length > 0
                                                            ? [
                                                                  {
                                                                      text: `${selectedFilterOptions.fundList.length} selected`,
                                                                      data: [...selectedFilterOptions.fundList],
                                                                  },
                                                              ]
                                                            : []
                                                    }
                                                />
                                            </div>
                                            <div className={`${baseClass}__filter`}>
                                                <Label>Single Fund / Subfund</Label>
                                                <MultiSelect
                                                    className={`${baseClass}__input-big`}
                                                    data={currentFilterOptions.subFundList}
                                                    value={selectedFilterOptions.subFundList}
                                                    placeholder="Select single funds / subfunds"
                                                    autoClose={false}
                                                    onChange={(event: MultiSelectChangeEvent): void => updateFilter(FilterOption.SubFund, event.value)}
                                                    tags={
                                                        selectedFilterOptions.subFundList.length > 0
                                                            ? [
                                                                  {
                                                                      text: `${selectedFilterOptions.subFundList.length} selected`,
                                                                      data: [...selectedFilterOptions.subFundList],
                                                                  },
                                                              ]
                                                            : []
                                                    }
                                                />
                                            </div>
                                            <div className={`${baseClass}__filter`}>
                                                <Label>ISIN</Label>
                                                <MultiSelect
                                                    className={`${baseClass}__input-big`}
                                                    data={currentFilterOptions.isinList}
                                                    value={selectedFilterOptions.isinList}
                                                    placeholder="Select ISINs"
                                                    autoClose={false}
                                                    onChange={(event: MultiSelectChangeEvent): void => updateFilter(FilterOption.Isin, event.value)}
                                                    tags={
                                                        selectedFilterOptions.isinList.length > 0
                                                            ? [
                                                                  {
                                                                      text: `${selectedFilterOptions.isinList.length} selected`,
                                                                      data: [...selectedFilterOptions.isinList],
                                                                  },
                                                              ]
                                                            : []
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className={`${baseClass}__filterGroup`}>
                                            <div className={`${baseClass}__filter`}>
                                                <Label>Distributor Country</Label>
                                                <MultiSelect
                                                    className={`${baseClass}__input-big`}
                                                    data={currentFilterOptions.distributorCountryList}
                                                    value={selectedFilterOptions.distributorCountryList}
                                                    placeholder="Select countries"
                                                    autoClose={false}
                                                    onChange={(event: MultiSelectChangeEvent): void =>
                                                        updateFilter(FilterOption.DistributorCountry, event.value)
                                                    }
                                                    tags={
                                                        selectedFilterOptions.distributorCountryList.length > 0
                                                            ? [
                                                                  {
                                                                      text: `${selectedFilterOptions.distributorCountryList.length} selected`,
                                                                      data: [...selectedFilterOptions.distributorCountryList],
                                                                  },
                                                              ]
                                                            : []
                                                    }
                                                />
                                            </div>
                                            <div className={`${baseClass}__filter`}>
                                                <Label>Distributor Type</Label>
                                                <MultiSelect
                                                    className={`${baseClass}__input-big`}
                                                    data={currentFilterOptions.distributorTypeList}
                                                    value={selectedFilterOptions.distributorTypeList}
                                                    placeholder="Select distributor types"
                                                    autoClose={false}
                                                    onChange={(event: MultiSelectChangeEvent): void => updateFilter(FilterOption.DistributorType, event.value)}
                                                    tags={
                                                        selectedFilterOptions.distributorTypeList.length > 0
                                                            ? [
                                                                  {
                                                                      text: `${selectedFilterOptions.distributorTypeList.length} selected`,
                                                                      data: [...selectedFilterOptions.distributorTypeList],
                                                                  },
                                                              ]
                                                            : []
                                                    }
                                                />
                                            </div>
                                            <div className={`${baseClass}__filter`}>
                                                <Label>Distributor</Label>
                                                <MultiSelect
                                                    className={`${baseClass}__input-big`}
                                                    data={currentFilterOptions.distributorList}
                                                    value={selectedFilterOptions.distributorList}
                                                    placeholder="Select distributors"
                                                    autoClose={false}
                                                    onChange={(event: MultiSelectChangeEvent): void => updateFilter(FilterOption.Distributor, event.value)}
                                                    tags={
                                                        selectedFilterOptions.distributorList.length > 0
                                                            ? [
                                                                  {
                                                                      text: `${selectedFilterOptions.distributorList.length} selected`,
                                                                      data: [...selectedFilterOptions.distributorList],
                                                                  },
                                                              ]
                                                            : []
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className={`${baseClass}__filterGroup`}>
                                            <div className={`${baseClass}__filter`}>
                                                <Label>Investor Country</Label>
                                                <MultiSelect
                                                    className={`${baseClass}__input-big`}
                                                    data={currentFilterOptions.investorCountryList}
                                                    value={selectedFilterOptions.investorCountryList}
                                                    placeholder="Select countries"
                                                    autoClose={false}
                                                    onChange={(event: MultiSelectChangeEvent): void => updateFilter(FilterOption.InvestorCountry, event.value)}
                                                    tags={
                                                        selectedFilterOptions.investorCountryList.length > 0
                                                            ? [
                                                                  {
                                                                      text: `${selectedFilterOptions.investorCountryList.length} selected`,
                                                                      data: [...selectedFilterOptions.investorCountryList],
                                                                  },
                                                              ]
                                                            : []
                                                    }
                                                />
                                            </div>
                                            <div className={`${baseClass}__filter`}>
                                                <Label>Investor</Label>
                                                <MultiSelect
                                                    className={`${baseClass}__input-big`}
                                                    data={currentFilterOptions.investorList}
                                                    value={selectedFilterOptions.investorList}
                                                    placeholder="Select investors"
                                                    autoClose={false}
                                                    onChange={(event: MultiSelectChangeEvent): void => updateFilter(FilterOption.Investor, event.value)}
                                                    tags={
                                                        selectedFilterOptions.investorList.length > 0
                                                            ? [
                                                                  {
                                                                      text: `${selectedFilterOptions.investorList.length} selected`,
                                                                      data: [...selectedFilterOptions.investorList],
                                                                  },
                                                              ]
                                                            : []
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className={`${baseClass}__filterGroup`}>
                                            <div className={`${baseClass}__filter`}>
                                                <Label>Fee Type</Label>
                                                <MultiSelect
                                                    className={`${baseClass}__input-big`}
                                                    data={currentFilterOptions.feeTypeList}
                                                    value={selectedFilterOptions.feeTypeList}
                                                    placeholder="Select fee type"
                                                    autoClose={false}
                                                    onChange={(event: MultiSelectChangeEvent): void => updateFilter(FilterOption.FeeType, event.value)}
                                                    tags={
                                                        selectedFilterOptions.feeTypeList.length > 0
                                                            ? [
                                                                  {
                                                                      text: `${selectedFilterOptions.feeTypeList.length} selected`,
                                                                      data: [...selectedFilterOptions.feeTypeList],
                                                                  },
                                                              ]
                                                            : []
                                                    }
                                                />
                                            </div>
                                            <div className={`${baseClass}__filterGroupBetween`}>
                                                <div className={`${baseClass}__filter`}>
                                                    <Label>From</Label>
                                                    <DropDownList
                                                        className={`${baseClass}__input-small`}
                                                        data={isQuarterDates ? fromDatesQuarter : fromDates}
                                                        value={isQuarterDates ? selectedFromDateQuarter : selectedFromDate}
                                                        onChange={onFromDateChange}
                                                    />
                                                </div>
                                                <div className={`${baseClass}__filter`}>
                                                    <Label>To</Label>
                                                    <DropDownList
                                                        className={`${baseClass}__input-small`}
                                                        data={isQuarterDates ? toDatesQuarter : toDates}
                                                        value={isQuarterDates ? selectedToDateQuarter : selectedToDate}
                                                        onChange={onToDateChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={`${baseClass}__filterGroupHorizontal`}>
                                            {/* <div className={`${baseClass}__filterRight`}> */}

                                            <div className={`${baseClass}__filter`}>
                                                {/* <div className={`${baseClass}__filterRight`}> */}
                                                <Label></Label>
                                                <Button themeColor="primary" onClick={resetFilter}>
                                                    Reset Filter
                                                    {/* <span className="k-icon k-i-filter-clear" /> */}
                                                    <span className="k-icon k-font-icon k-i-filter-clear" />
                                                </Button>
                                            </div>
                                            <div className={`${baseClass}__filter`}>
                                                <Label></Label>
                                                <Button themeColor="primary" onClick={toggleIsQuarterDates}>
                                                    {`${isQuarterDates ? "Show Months" : "Show Quarters"}`}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div style={{ paddingTop: "50px" }}>
                                <div data-testid="chart-combined">
                                    <Chart options={combinedOptions} series={combinedSeries} height={500} />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                )}
            </div>
            <BackToTop />
        </Page>
    );
};

export default DistributionDashboard;
