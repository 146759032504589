import React, { useState, useEffect } from "react";
import classNames from "classnames";

import { Grid, GridColumn as Column, GridCellProps, GridFilterChangeEvent, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { process, SortDescriptor, filterBy, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Chip } from "@progress/kendo-react-buttons";

import { useAppUserContext } from "@src/common/Context";
import { useWindowDimensions } from "@src/common/hooks";
import { ERole } from "@src/common/types";
import { Icon, LoadingPanel } from "@components/common";
import ResponsiveTable from "@components/common/ResponsiveTable";

import { ILoad } from "../types";
import ValidationReport from "../ValidationReport";
import "./DataLoads.scss";
import { mapStatusClassToColor } from "@src/common/util";

const baseClass = "acl-static-data-loads";

interface IState {
    showReport: boolean;
    dataState: {
        sort: SortDescriptor[];
        take: number;
        skip: number;
    };
    filteredList: ILoad[];
    filter: CompositeFilterDescriptor;
}

interface IProps {
    dataset: ILoad[];
    loading: boolean;
    isCommissionData?: boolean;
}

const initialState: IState = {
    showReport: false,
    dataState: {
        sort: [{ field: "id", dir: "desc" }],
        take: 10,
        skip: 0,
    },
    filteredList: [],
    filter: undefined,
};

const DataLoads: React.FC<IProps> = ({ dataset, loading, isCommissionData = false }) => {
    const [state, setState] = useState<IState>(initialState);
    const [appUser, _setAppUser] = useAppUserContext();
    const { width } = useWindowDimensions();

    const StatusCell: React.FC<GridCellProps> = ({ dataItem }) => {
        const loadId = dataItem.id;
        const [state, setState] = useState<IState>(initialState);

        const classes: string = classNames(
            `${baseClass}__status ${baseClass}__status--${dataItem.statusClass || "warning"}`,
            !dataItem.hasValidationReport && `${baseClass}__status-disabled`,
        );

        const onClick = (): void => {
            if (!dataItem.hasValidationReport || (isCommissionData && !appUser.roles.includes(ERole.CommissionManager))) {
                return;
            } else {
                setState(prevState => ({ ...prevState, showReport: true }));
            }
        };
        const onClose = (): void => setState(prevState => ({ ...prevState, showReport: false }));

        return (
            <td className={`${baseClass}__status-wrapper`}>
                <Tooltip openDelay={100} position={width > 1024 ? "left" : "top"} anchorElement="target" className={`${baseClass}__tooltip`}>
                    <Chip
                        text={dataItem.formattedStatus || "Unknown"}
                        value="chip"
                        fillMode={"solid"}
                        themeColor={dataItem.statusClass ? mapStatusClassToColor(dataItem.statusClass) : "warning"}
                        icon={"k-i-acl-icon acl-i-notes-book-text"}
                        disabled={state.showReport}
                        className={classes}
                        onClick={onClick}
                    />
                    <Icon title={dataItem.statusInfo} className={`${baseClass}__tooltip`} name="question-circle" />
                    {state.showReport && <ValidationReport loadId={loadId} onClose={onClose} />}
                </Tooltip>
            </td>
        );
    };

    const columns = [
        { field: "id", title: "Load ID", width: 90 },
        { field: "fileName", title: "Name" },
        { field: "validFrom", title: "Valid From", width: 125 },
        { field: "loadDate", title: "Load Date", width: 125 },
        { field: "lastUpdate", title: "Last Update", width: 125 },
        {
            field: "formattedStatus",
            title: "Status",
            width: 190,
            cell: StatusCell,
        },
    ];

    const filterChange = (event: GridFilterChangeEvent): void => {
        setState({
            ...state,
            dataState: { ...state.dataState, skip: 0 },
            filteredList: filterBy(dataset, event.filter),
            filter: event.filter,
        });
    };

    const sortChange = (event: GridSortChangeEvent): void => {
        setState({
            ...state,
            dataState: { ...state.dataState, sort: event.sort },
        });
    };

    const onDataStateChange = ({ dataState }): void => setState({ ...state, dataState });

    useEffect(() => {
        setState({
            ...state,
            filteredList: dataset,
        });
    }, [dataset]);

    return (
        <div className={`${baseClass} `}>
            <ResponsiveTable>
                <Grid
                    data={process(state.filteredList, state.dataState)}
                    sortable={true}
                    sort={state.dataState.sort}
                    take={state.dataState.take}
                    skip={state.dataState.skip}
                    filterable={true}
                    filter={state.filter}
                    onFilterChange={filterChange}
                    onSortChange={sortChange}
                    scrollable="none"
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: "input",
                        pageSizes: false,
                        previousNext: true,
                    }}
                    onDataStateChange={onDataStateChange}
                >
                    {columns.map(({ field, ...other }) => (
                        <Column key={field} field={field} {...other} />
                    ))}
                </Grid>
            </ResponsiveTable>
            {loading && <LoadingPanel />}
        </div>
    );
};

DataLoads.defaultProps = {
    dataset: [],
    loading: false,
};

export default DataLoads;
